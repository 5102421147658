//============================================================================ common

// 모달처리
.modal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.4);
  > main {
    position: absolute;
    top: 45%;
    left: 50%;
    padding: 10px;
    border-radius: 5px;
    transform: translate(-50%, -50%);
    // width: 80%;
    min-width: 300px;
    background-color: #ffffff;
    overflow: 'auto';
  }
}
// 패널(그림자효과)
.panel {
  background-color: var(--COLOR_WHITE);
  border: 1px solid var(--COLOR_GRAY2);
  border-radius: 8px;
  flex-direction: column;
  // -webkit-mask-image: -webkit-radial-gradient(white, black);
  box-shadow: 0px 6px 10px rgba(6, 25, 56, 0.07);
  background: var(--COLOR_WHITE);
}
